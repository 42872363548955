import {
  scriptImported,
  interstitialAdLoaded,
  stickyFooterRefreshed,
  CREATE_AD,
  DESTROY_AD,
  adCreated,
  adDestroyed,
  SCRIPT_IMPORTED,
} from '../store/modules/adnimation'
import { ROUTE_FOUND } from '../store/modules/router'
import { combineEpics, ofType } from 'redux-observable'
import { map, mergeMap, tap, take, filter, withLatestFrom } from 'rxjs'
import { logObservableError } from '../utilities/logs'
import { TCDATA_READY } from '../store/modules/consentManagement'
import { isNil } from 'ramda'

// importScriptEpic :: Epic -> Observable Action.SCRIPT_IMPORTED
export const importScriptEpic = (action$, _, { document, logger }) =>
  action$.pipe(
    ofType(TCDATA_READY),
    take(1),
    mergeMap(() => new Promise(resolve => {
      const script = document.createElement('script')
      script.async = true
      script.src = 'https://adncdn.net/5eyDSZ2YeO'
      script.onload = () => resolve()

      document.querySelector('head').appendChild(script)
    })),
    map(scriptImported),
    logObservableError('adnimation :: importScriptEpic', logger),
  )

// loadInterstialAdEpic :: Epic -> Observable Action.INTERSTITIAL_AD_LOADED
export const loadInterstialAdEpic = (action$, _, { logger, window }) =>
  action$.pipe(
    ofType(SCRIPT_IMPORTED),
    take(1),
    tap(() => {
      window.googletag = window.googletag || { cmd: [] }
      window.googletag.cmd = Array.isArray(window.googletag.cmd) ? window.googletag.cmd : []
      window.googletag.cmd.push(function () {
        var slot = window.googletag.defineOutOfPageSlot(
          '/22034362842/i24Web/i24Web_Interstitial',
          window.googletag.enums.OutOfPageFormat.INTERSTITIAL,
        )

        if (slot) { 
          slot.addService(window.googletag.pubads()).setConfig({
            interstitial: {
              triggers: {
                navBar: true,
                unhideWindow: true,
              },
            },
          })

          window.googletag.enableServices()
          window.googletag.display(slot)
        }
      })
    }),
    map(interstitialAdLoaded),
    logObservableError('adnimation :: loadInterstialAdEpic', logger),
  )

 // createAdEpic :: Epic -> Observable Action.AD_CREATED
 export const createAdEpic = (action$, _, { logger, adn }) =>
   action$.pipe(
     ofType(CREATE_AD),
     filter(() => !isNil(adn())),
     tap(({ id }) => adn().createAd(id)),
     map(({ id }) => adCreated(id)),
     logObservableError('adnimation :: createAdEpic', logger),
   )

 // destroyAdEpic :: Epic -> Observable Action.AD_DESTROYED
 export const destroyAdEpic = (action$, _, { logger, adn }) =>
  action$.pipe(
    ofType(DESTROY_AD),
    filter(() => !isNil(adn())),
    tap(({ id }) => adn().destroyAd(id)),
    map(({ id }) => adDestroyed(id)),
    logObservableError('adnimation :: destroyAdEpic', logger),
  )

// refreshStickyFooterEpic :: Epic -> Action.STICKY_FOOTER_REFRESHED
export const refreshStickyFooterEpic = (action$, state$, { logger, adn }) =>
  action$.pipe(
    ofType(ROUTE_FOUND),
    withLatestFrom(state$),
    filter(([ _, state ]) =>
      !isNil(adn())
      && state.adnimation.scriptImported
      && state.router.matchesCounter > 1,
    ),
    tap(() => {
      const id = '/22034362842/i24Web/Sticky_Footer'

      adn().destroyAd(id)
      adn().createStickyBanner()
      adn().createAd(id)
    }),
    map(stickyFooterRefreshed),
    logObservableError('adnimation :: refreshStickyFooterEpic', logger),
  )

export default combineEpics(
  importScriptEpic,
  loadInterstialAdEpic,
  createAdEpic,
  destroyAdEpic,
  refreshStickyFooterEpic,
)
